<template>
  <b-modal
    size="sm"
    :id="'modal-confirm'"
    :title="nameModal"
    ok-only
    hide-footer
  >

    <b-card-text>
      <b-row class="mb-2">
        <b-col md="6">
          <div class="text-right">
            <b-button variant="primary" @click="confirm(false)">
              <b-spinner small v-if="isSaving" />No
            </b-button>
          </div>
        </b-col>
        <b-col md="6">
          <div class="text-left">
            <b-button variant="primary" @click="confirm(true)">
              <b-spinner small v-if="isSaving" />Si
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card-text>
  </b-modal>
</template>

<script>
export default {
  async mounted() {},
  props: {
    nameModal: {
      type: String,
      required: true,
    },
     
  },
  data() {
    return {
      isSaving: false,
    };
  },
  computed: {},
  methods: {
    async confirm(state) {
      await this.$emit("send-Authorization",state);
      this.$root.$emit('bv::hide::modal', 'modal-confirm')
    },
  },
};
</script>


