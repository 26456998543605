<template>
  <div>
    <HeaderPayment />

    <div class="misc-wrapper">
      <div>
        <b-container
          v-if="loadvalid"
          class="col-sm-12 mb-12 flex-fill container"
        >
          <b-row class="justify-content-center">
            <b-card v-if="nameUser == ''" class="col-sm-12 text-center">
              <h3 class="titleLogin">⚠️ No tiene permisos para esta página.</h3>
            </b-card>
            <b-card
              v-if="userAuthPage.isValid == false && nameUser != ''"
              class="col-sm-12 text-center login"
            >
              <h3 class="titleLogin">Bienvenido</h3>
              <div>{{ nameUser.name }} {{ nameUser.lastname }}</div>
              <div class="mt-2">
                <ValidationProvider
                  rules="required"
                  name="Ingrese la contraseña"
                >
                  <b-form-group
                    label="Ingrese la contraseña"
                    slot-scope="{ valid, errors }"
                  >
                    <b-form-input
                      type="password"
                      v-model="userAuthPage.password"
                      :state="errors[0] ? false : valid ? true : null"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      >{{
                        errors[0]
                          ? "Se debe indicar la clave de autorización del usuario"
                          : ""
                      }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </div>
              <div class="">
                <b-button primary="success" @click="login()"> Login </b-button>
              </div>
            </b-card>

            <b-card v-if="userAuthPage.isValid == true" class="col-sm-8">
              <div class="card-header">
                <div class="col-6 pl-0 mb-2">
                  <h3 class="titleLogin">{{ saludo }}</h3>
                  <span class="nameUser">
                    <feather-icon icon="UserIcon" size="16" />{{
                      nameUser.name
                    }}
                    {{ nameUser.lastname }}</span
                  >
                </div>
                <div
                  :class="
                    'col-6 mb-2 estatus' + statusAutho.id + ' text-center'
                  "
                >
                  <h4>Estatus:</h4>
                  <span>{{ statusAutho.title }}</span>  {{groups[0].infoQuote.userAuthorized}}
                </div>
                <h4 class="card-title">Infomacion de la cotizacion</h4>
                <div class="moonicon">
                  <feather-icon icon="MoonIcon" size="16" />
                  <!-- :fill="'#ffd600'" -->
                  <b> Noches: {{ groups[0].nightsGroup }} </b>
                </div>
              </div>
              <b-card-body>
                <b-row v-for="groupsList in groups" :key="groupsList.id">
                  <b-col class="title col-sm-4 mb-1"
                    ><b>Nombre del grupo</b>
                    <div>{{ groupsList.name }}-{{ groupsList.contact }}</div>
                  </b-col>
                  <b-col class="title col-sm-4 mb-1"
                    ><b>Teléfono</b>
                    <div>{{ groupsList.phonecontact }}</div>
                  </b-col>
                  <b-col class="title col-sm-4 mb-1"
                    ><b>E-mail</b>
                    <div>{{ groupsList.emailcontact }}</div>
                  </b-col>
                  <b-col class="title col-sm-4 mb-1"
                    ><b>Fecha de llegada</b>
                    <div>{{ groupsList.arrivaldate }}</div>
                  </b-col>
                  <b-col class="title col-sm-4 mb-1"
                    ><b>Fecha de salida</b>
                    <div>{{ groupsList.departuredate }}</div>
                  </b-col>
                  <b-col class="title col-sm-4 mb-1"
                    ><b>Nombre del hotel</b>
                    <div>{{ groupsList.hotelname }}</div>
                  </b-col>
                  <b-col class="title col-sm-4 mb-1"
                    ><b>Tipo de grupo</b>
                    <div>{{ groupsList.tipogrupo.tipogruponame }}</div>
                  </b-col>
                  <b-col class="title col-sm-4 mb-1"
                    ><b>Plan de alimentos</b>
                    <div>{{ groupsList.mealplan.name }}</div>
                  </b-col>
                  <b-col class="title col-sm-4 mb-1"
                    ><b>Tipo de cambio</b>
                    <div>{{ groupsList.typeCurrency.code }}</div>
                  </b-col>
                  <b-col class="title col-sm-4 mb-1"
                    ><b>Asesor de Ventas</b>
                    <div>
                      {{ groupsList.createdby.first_name }}
                      {{ groupsList.createdby.last_name }}
                    </div>
                  </b-col>

                  <b-col class="title col-12 mt-1">
                    <h4>Beneficios</h4>
                    <ul>
                      <li
                        v-for="(benefits, index) in groupsList.benefits.data"
                        :key="index"
                      >
                        {{ benefits }}
                      </li>
                    </ul>
                  </b-col>
                  <b-col class="title col-sm-12 mt-1">
                    <div v-html="groupsList.tablerooms"></div>
                  </b-col>
                </b-row>
                <b-col class="title col-sm-12 mt-1 p-0">
                  <span>Comentarios</span>
                  <ValidationProvider rules="max:255" name="comments">
                    <b-form-group slot-scope="{ valid, errors }">
                      <b-input-group>
                        <b-form-textarea
                          v-model="comments"
                          rows="2"
                          max-rows="3"
                          :state="errors[0] ? false : valid ? true : null"
                        >
                        </b-form-textarea>
                        <b-form-invalid-feedback>
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-input-group>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-row class="justify-content-center">
                  <b-col
                    v-if="
                     statusAutho.id == 2 || statusAutho.id== 0
                    "
                    class="title buttons mt-1"
                  >
                    <div class="text-right">
                      <b-button
                        variant="success"
                        @click="
                          showModalConfirm(
                            true,
                            '¿Quiere autorizar la solicitud?'
                          )
                        "
                      >
                        Autorizar
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    v-if="
                      statusAutho.id == 1 || statusAutho.id == 0
                    "
                    class="title buttons mt-1"
                  >
                    <div class="text-left">
                      <b-button
                        variant="danger"
                        @click="
                          showModalConfirm(
                            false,
                            '¿Quiere rechazar la solicitud?'
                          )
                        "
                      >
                        {{ nameButton }}
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>
              <!-- form -->
            </b-card>
          </b-row>
        </b-container>
        <div v-else class="text-center">
          <b-spinner label="Loading..." variant="success" />
        </div>
      </div>
      <ModalConfirm
        :nameModal="titleSend"
        @send-Authorization="sendAuthorization"
      />
      <FooterPayment> </FooterPayment>
    </div>
  </div>
</template>


<script>
import HeaderPayment from "@/modules/shop/components/HeaderPayment";
import FooterPayment from "@/modules/shop/components/FooterPayment";
import { mapState, mapActions, mapMutations } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ModalConfirm from "@/modules/groups/components/ModalConfirm";
import { status } from "@/data/data";
export default {
  async mounted() {
    const payload = { id: this.idgrup, email: this.email };
    this.loadvalid = false;
    await this.fetchLoginLanding(payload);
    await this.fetchRoomsLanding(payload);
    this.loadvalid = true;
    this.userAuthPage.password = "";
  },
  components: {
    HeaderPayment,
    FooterPayment,
    ModalConfirm,
  },

  data() {
    return {
      idgrup: this.$route.query.idgrup,
      email: this.$route.query.email,
      loadvalid: false,
      comments: "",
      titleSend: "",
      authorize: false,
      status,
      nameButton: "Cambiar a Rechazado",
    };
  },
  computed: {
    ...mapState("groups", ["groups", "userAuthPage", "nameUser"]),
    ...mapMutations("groups", ["setGroups"]),

    saludo() {
      let fecha = new Date();
      let hora = fecha.getHours();
      let saludos;
      if (hora >= 0 && hora < 12) {
        saludos = "Buenos Días";
      }

      if (hora >= 12 && hora < 18) {
        saludos = "Buenas Tardes";
      }

      if (hora >= 18 && hora < 24) {
        saludos = "Buenas Noches";
      }
      return saludos;
    },
    statusAutho() {
      return this.status.find(
        (d) => d.id === parseInt(this.groups[0].infoQuote.statusAuthorized)
      );
    },
  },
  methods: {
    ...mapActions("auth", ["signIn"]),
    ...mapActions("groups", [
      "fetchRoomsLanding",
      "fetchLoginLanding",
      "fetchAuthorization",
    ]),

    async sendAuthorization(state) {
      if (state != false) {
        const payload = {
          id:this.nameUser.id,
          idGrupo: this.idgrup,
          email: this.nameUser.email,
          isAutorizado: this.authorize,
          notes: this.comments,
          name: this.nameUser.name +' '+this.nameUser.lastname
        };
        const reload = { id: this.idgrup, email: this.email };
        await this.fetchAuthorization(payload);
        await this.fetchRoomsLanding(reload);
        if (statusAutho.id ==0) {
          this.nameButton = "Rechazar";
        }else{
          statusAutho.id ="Cambiar a Rechazado"
        }
      }
    },

    async login() {
      const payload = {
        username: this.nameUser.username,
        password: this.userAuthPage.password,
      };
      await this.signIn(payload);
    },

    showModalConfirm(state, title) {
      this.authorize = state;
      if (this.comments == "" && state == false) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Aviso",
              icon: "BellIcon",
              text: `Favor de poner una nota al momento de rechazar la cotizacion `,
              variant: "warning",
            },
          },
          {
            position: "bottom-right",
            timeout: 7000,
          }
        );
      } else {
        this.titleSend = title;
        this.$root.$emit("bv::show::modal", "modal-confirm");
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";

.container-xl,
.container-lg,
.container-md,
.container-sm,
.container {
  max-width: 1440px;
}

.estatus2.text-center {
  color: red;
}
.estatus1.text-center {
  color: green;
}

.title.buttons.mt-1.col div {
  margin: 0 auto;
  display: table;
  /* width: 269px; */
}
.login {
  max-width: 300px;
}
.container {
  padding-bottom: 52px;
}

table.dataframe th:first-child {
  display: none;
}

table.dataframe {
  text-align: center;
}

table.dataframe th {
  padding: 0px 7px 1px 7px;
  font-size: 12px;
}

table.dataframe {
  margin: 0 auto;
}
.misc-wrapper {
  min-height: 9vh !important;
}

.buttons {
  text-align: center;
}

.moonicon {
  display: inline-block;
}

svg.feather.feather-user {
  line-height: normal;
  margin: -7px 7px 0 0px;
}

h4.card-title {
  display: inline-block;
}
.titleLogin {
  display: block;
}

@media (max-width: 768px) {
  .title {
    font-size: 12px;
  }

  .card-body {
    padding: 0 0 35px 0;
  }
  table.dataframe th {
    font-size: 10px;
  }

  table td {
    font-size: 10px;
  }
}
</style>
<style scoped src="@/assets/css/success.css"></style>
